import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useActions, useValues } from 'kea'
import { A } from 'kea-router'
import { OMSProvider } from 'lib/Types'
import OpenSaasPlanManagementButton from 'lib/components/saas-plan-management/OpenSaasPlanManagementButton'
import { authLogic } from 'lib/logics/authLogic'
import { FEATURE_FLAGS, featureFlagLogic } from 'lib/logics/featureFlagsLogic'
import { Scene, sceneLogic, scenesToURLs } from 'lib/logics/sceneLogic'
import { userLogic } from 'lib/logics/userLogic'
import { IconType } from 'react-icons'
import { BsGear, BsStars, BsTruck } from 'react-icons/bs'
import { ImCloudUpload, ImLab, ImTable } from 'react-icons/im'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { TbMicroscope, TbPhotoSensor, TbPlugConnected, TbShoppingCart } from 'react-icons/tb'
import { integrationsLogic } from 'scenes/integrations/integrationsLogic'

import { ReactNode } from 'react'

import './SideBar.scss'

export interface NavButtonProps {
  label: string
  scene: Scene
  isSelected: boolean
  isBeta?: boolean
  needsAttention?: boolean
  attentionMessage?: string
  defaultSegmentParams?: Record<string, string>
  internalOnly?: boolean
}

export interface PrimaryNavButtonProps extends NavButtonProps {
  icon: IconType
  subItems?: NavButtonProps[]
}

function PrimaryNavButton({
  icon,
  label,
  scene,
  isSelected,
  subItems,
  isBeta,
  internalOnly = false,
  defaultSegmentParams = {},
}: PrimaryNavButtonProps): JSX.Element | null {
  const isSelectedAsParent = subItems?.some((subItem) => subItem.isSelected)
  const { featureFlags } = useValues(featureFlagLogic)
  if (internalOnly && !featureFlags[FEATURE_FLAGS.INTERNAL_USERS]) return null

  return (
    <>
      <Box
        className="SideBar"
        mt="2"
        borderLeftRadius="lg"
        py="1"
        pl="2"
        fontSize="lg"
        backgroundColor={isSelected && !isSelectedAsParent ? 'brandBrownLight' : undefined}
        fontWeight={isSelectedAsParent || isSelected ? 'bold' : undefined}
      >
        <Link className="SideBar__link-label" as={A} href={scenesToURLs[scene](defaultSegmentParams)}>
          <HStack>
            <Icon w="5" as={icon}></Icon>
            <Text>{label}</Text>
            {internalOnly && <MdOutlineAdminPanelSettings />}
            {isBeta ? (
              <Tag size={'sm'} variant="solid" backgroundColor="brandGreen">
                BETA
              </Tag>
            ) : null}
          </HStack>
        </Link>
      </Box>
      {subItems && (
        <Box mt="2" borderLeft={'solid'} borderLeftWidth={0.3} borderColor={'brandTan'} ml="4" pl="2">
          {subItems?.map((subItem) => (
            <Box
              key={subItem.scene}
              pl="2"
              py="2px"
              mb="1"
              borderLeftRadius={'md'}
              backgroundColor={subItem.isSelected ? 'brandBrownLight' : undefined}
              fontWeight={subItem.isSelected ? 'bold' : 'normal'}
            >
              <Link
                className="SideBar__link-label"
                as={A}
                href={scenesToURLs[subItem.scene](subItem.defaultSegmentParams)}
              >
                <Tooltip label={subItem.attentionMessage}>
                  <HStack>
                    <Text>{subItem.label}</Text>
                    {internalOnly && <MdOutlineAdminPanelSettings />}
                    {subItem.needsAttention && <Box borderRadius="full" backgroundColor="yellow.500" h="2" w="2"></Box>}
                  </HStack>
                </Tooltip>
              </Link>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}

export function LoggedInNavButtons(): JSX.Element {
  const { organizationName, hasOrganization, user, bulkRecommendationEnabled, showSensorBilling } = useValues(userLogic)
  const { scene } = useValues(sceneLogic)
  const { logout } = useActions(authLogic)
  const { integrations } = useValues(integrationsLogic)

  return (
    <Flex flex="1" flexDirection="column">
      <Box flex="1" ml="2">
        {hasOrganization ? (
          <>
            <PrimaryNavButton
              icon={BsStars}
              label={'Shipping Copilot'}
              scene={Scene.copilot}
              isSelected={scene === Scene.copilot}
            />
            <PrimaryNavButton
              icon={BsTruck}
              label={'Shipments'}
              scene={Scene.shipments}
              isSelected={scene === Scene.shipments}
            />
            <PrimaryNavButton
              // TODO make KicIconType work here
              icon={TbPhotoSensor}
              label={'Sensors'}
              scene={Scene.sensors}
              isSelected={scene === Scene.sensors}
            />
            <PrimaryNavButton
              icon={TbMicroscope}
              label="Insights"
              scene={Scene.insights}
              isSelected={scene === Scene.insights}
            />
            <PrimaryNavButton
              icon={ImCloudUpload}
              label={'Imports'}
              scene={Scene.shipmentImports}
              isSelected={scene === Scene.shipmentImports || scene === Scene.importShipments}
            />
            <PrimaryNavButton
              icon={BsGear}
              label={'Settings'}
              scene={Scene['settings/recommendations']}
              isSelected={scene === Scene['settings/recommendations']}
              subItems={[
                {
                  label: 'Recommendations',
                  scene: Scene['settings/recommendations'],
                  isSelected: scene === Scene['settings/recommendations'],
                },
                // TODO: tear out all the code relating to old Packaging Models
                // {
                //   label: 'Packaging models',
                //   scene: Scene.packagingConfigSettings,
                //   isSelected: scene === Scene.packagingConfigSettings,
                // },
                {
                  label: 'Distribution Centers',
                  scene: Scene['settings/distribution-centers'],
                  isSelected: scene === Scene['settings/distribution-centers'],
                },
                {
                  label: 'Shipping Methods',
                  scene: Scene['settings/shipping-methods'],
                  isSelected: scene === Scene['settings/shipping-methods'],
                },
                {
                  label: 'Coolant Blocks',
                  scene: Scene['settings/coolant-blocks'],
                  isSelected: scene === Scene['settings/coolant-blocks'],
                },
                {
                  label: 'Packaging',
                  scene: Scene['settings/packaging'],
                  isSelected: scene === Scene['settings/packaging'],
                },
                {
                  label: 'Products',
                  scene: Scene['settings/products'],
                  isSelected: scene === Scene['settings/products'],
                },
                {
                  label: 'Carrier Settings',
                  scene: Scene['settings/carrier-settings'],
                  isSelected: scene === Scene['settings/carrier-settings'],
                },
              ]}
            />
            <PrimaryNavButton
              icon={TbPlugConnected}
              label={'Integrations'}
              scene={Scene.integrations}
              isSelected={scene === Scene.integrations}
              subItems={Object.values(integrations)
                .filter((integration) => integration.uuid)
                .map((integration) => ({
                  label: integration.name,
                  scene:
                    integration.provider === OMSProvider.Shopify
                      ? Scene.shopifyIntegration
                      : Scene.shipStationIntegration,
                  needsAttention: integration.is_sync_active === false,
                  attentionMessage:
                    integration.is_sync_active === false ? `${integration.name} sync is not active` : undefined,
                  isSelected:
                    (integration.provider === OMSProvider.Shopify && scene === Scene.shopifyIntegration) ||
                    (integration.provider === OMSProvider.ShipStation && scene === Scene.shipStationIntegration),
                  defaultSegmentParams: { tab: 'sync-log' },
                }))}
            />
            <PrimaryNavButton
              icon={ImLab}
              label={'Simulation lab'}
              scene={Scene.simulationLab}
              isSelected={scene === Scene.simulationLab}
            />
            {bulkRecommendationEnabled && (
              <PrimaryNavButton
                icon={ImTable}
                label={'Bulk recs.'}
                scene={Scene.bulkRecommendationConfigs}
                isSelected={scene === Scene.bulkRecommendationConfigs}
              />
            )}
          </>
        ) : null}
      </Box>
      <Divider></Divider>
      <Box ml={2}>
        {showSensorBilling && (
          <PrimaryNavButton
            icon={TbShoppingCart}
            label="Order Sensors"
            scene={Scene.buySensors}
            isSelected={scene === Scene.buySensors}
          />
        )}
        <OpenSaasPlanManagementButton />
      </Box>
      <Box mt="5" px="3">
        <Popover placement="top-end" trigger="hover">
          <PopoverTrigger>
            <Flex alignItems="center">
              <Avatar size="sm" name={user?.first_name || user?.email} mr="2" />
              <Box flex="1" width="20">
                <Text fontSize="lg" textTransform="capitalize">
                  {user?.email}
                </Text>
              </Box>
            </Flex>
          </PopoverTrigger>
          <PopoverContent color="black" maxW="14rem" data-js-focus-visible="none">
            <PopoverBody>
              <Box>
                <Text textTransform="uppercase" fontSize="sm" mb="1" fontWeight="bold">
                  Signed in as:
                </Text>
                <Flex>
                  <Box>
                    <Text fontSize="lg" textTransform="capitalize" overflowWrap={'anywhere'}>
                      {user?.email}
                    </Text>
                    <Text color="gray.600" fontSize="sm" textTransform="capitalize" mt="0">
                      {organizationName ? `(${organizationName})` : ''}
                    </Text>
                  </Box>
                </Flex>
                <Divider mt="2"></Divider>
                <Button variant="link" colorScheme="red" mt="2" onClick={logout}>
                  Logout
                </Button>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  )
}

export function SideBar({ children, hideSideBar = false }: { children: ReactNode; hideSideBar: boolean }): JSX.Element {
  const { isAuthenticated } = useValues(authLogic)
  const { scene } = useValues(sceneLogic)

  return (
    <Box position="relative" flexGrow="1" width="100vw" height="100vh" display="flex" overflow="hidden">
      {!hideSideBar && (
        <Box
          zIndex="1"
          position="relative"
          flexShrink="0"
          height="initial"
          width="14rem"
          overflow="hidden"
          backgroundColor="brandBrown"
          boxShadow="base"
          borderTopRightRadius={'xl'}
          borderBottomRightRadius={'xl'}
        >
          <Box
            position="sticky"
            top="0"
            width="full"
            height="100vh"
            overflowY="auto"
            py="5"
            color="brandTan"
            display="flex"
            flexDirection="column"
          >
            <Image src={'/kic-logo.svg'} alt={'Keep it Cool'} height={'5rem'} />

            <Divider></Divider>

            {isAuthenticated ? (
              <LoggedInNavButtons />
            ) : (
              <Box flex="1">
                <Link as={A} ml="10" href={scenesToURLs.login()}>
                  <Text fontWeight={scene === Scene.login ? 'bold' : undefined} fontSize="lg">
                    Login
                  </Text>
                </Link>
                <Link as={A} ml="10" href={scenesToURLs['create-account']()}>
                  <Text fontWeight={scene === Scene.createAccount ? 'bold' : undefined} fontSize="lg">
                    Create account
                  </Text>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box flex="1" overflowX="auto" overflowY={'auto'}>
        {children}
      </Box>
    </Box>
  )
}
